import BaseTab from "@/components/Base/BaseTab.vue";
import { helperMixin } from "@/mixins";
import { defineComponent, shallowRef } from "vue";
import CrewOnBoard from "@/components/Planning/CrewOnBoard.vue";
import fleetHttp from "@/http/fleet";
export default defineComponent({
    mixins: [helperMixin],
    components: { BaseTab, CrewOnBoard },
    data() {
        return {
            fleet: {},
            tabs: []
        };
    },
    async mounted() {
        var _a, _b;
        this.fleet = (await fleetHttp().read((_a = this.currentRoute.params) === null || _a === void 0 ? void 0 : _a.id)).response.data;
        this.tabs = [
            {
                title: this.trans("layout.fleet.crew_on_board"),
                isDefault: true,
                component: shallowRef(CrewOnBoard),
                componentProps: {
                    fleetId: (_b = this.currentRoute.params) === null || _b === void 0 ? void 0 : _b.id
                }
            }
        ];
    }
});
